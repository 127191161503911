import axios from "axios";
import util from "./util";
import Crypto from "@/utils/crypto";

// import router from "@/router/index";
import Cookies from "js-cookie";

const uuid = require("uuid").v4;

// create an axios instance
const CancelToken = axios.CancelToken;
const apiCach = {
  taskList: [] /** 请求任务列表 */,
  /** 新增任务 */
  addTask(config, cancelToken) {
    const url = [
      config.method, // 方法
      config.url, // 路径
      JSON.stringify(config.params), // 查询参数
      JSON.stringify(config.data), // 提交数据
    ].join("&"); // 转换成字符串
    this.taskList.push({ original: url, cancelToken });
  },
  /** 删除任务 */
  deleteTask(config) {
    const curUrl = [
      config.method, // 方法
      config.url, // 路径
      JSON.stringify(config.params), // 查询参数
      JSON.stringify(config.data), // 提交数据
    ].join("&"); // 转换成字符串
    for (let i in this.taskList) {
      if (this.taskList[i]["original"] == curUrl) {
        this.taskList[i].cancelToken("");
        this.taskList.splice(i, 1);
        break;
      }
    }
  },
};
const service = axios.create({
  // baseURL: util.baseAdmin, // api的base_url
  timeout: 1500000, // request timeoututil.ajax
  // withCredentials: true
});
// axios.defaults.withCredentials=true;
// service.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

// request interceptor
service.interceptors.request.use(
  config => {
    // Do something before request is sent
    config.cancelToken = new CancelToken(c => {
      /** 删除任务 */
      apiCach.deleteTask(config, true, c);
      /** 新增任务 */
      apiCach.addTask(config, c);
    });
    if (!/^(\/api|\/dapi|\/fapi|\/nxfi)/.test(config.url)) {
      config.baseURL = util.baseAdmin;
    }
    if (window._storage_ && window._storage_.usertoken) {
      const token = Crypto.decrypto(window._storage_.usertoken);
      const error = "Invalid token";
      if (!token) return Promise.reject(new Error(error));
      config.headers.usertoken = token;
    } else if (Cookies.get("usertoken")) {
      config.headers.usertoken = Cookies.get("usertoken"); // 让每个请求携带token-- usertoken为自定义key 请根据实际情况自行修改
    }

    config.headers["Content-language"] = localStorage.lang === "zh-CN" ? "CN" : "EN";
    const requestId =
      config.method.toLocaleUpperCase() === "POST" &&
      (config.params && config.params.requestId)
        ? config.params.requestId
        : uuid();

    config.headers["requestId"] = requestId;

    return config;
  },
  error => {
    // Do something with request error
    console.log(error); // for debug
    Promise.reject(error);
  }
);

// respone interceptor
service.interceptors.response.use(
  response => {
    apiCach.deleteTask(response.config, false);
    /**
     * 下面的注释为通过response自定义code来标示请求状态，当code返回如下情况为权限有问题，登出并返回到登录页
     * 如通过xmlhttprequest 状态码标识 逻辑可写在下面error中
     */
    const res = response.data;

    if (
      response.config.headers &&
      response.config.headers["Content-language"] === "EN" &&
      response.data &&
      response.data.msg === "unknown error request"
    ) {
      return Promise.reject(res.data.msg);
    }

    return response;
  },
  error => {
    console.log("err111" + error); // for debug
    // if (error != "Cancel") {
      
    // }

    return Promise.reject(error);
  }
);

export default service;

<template>
<main>
    <section class="developer_page">
        <div class="container">
            <div class="row">
                <div class="col-lg-3">
                    <div class="sidebar_area_left">
                        <div class="search_wrap">
                            <form>
                                <input type="text" class="search_field" placeholder="关键字搜索..." v-model="docSearchVal" @input="docSearchFun()" />
                                <button class="search_btn"></button>
                            </form>
                            <p>
                                <i><a href="https://github.com/nxtele/nxcloud-doc-en/wiki" target="_blank">点击此处查看更多更新</a></i>
                            </p>
                        </div>
                        <div class="sidebar_options_wrap">
                            <div class="accordion" id="accordionExample">
                                <div class="accordion-item">
                                    <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse_message" aria-expanded="true" aria-controls="collapse_message">
                                        <img src="../../assets/images/chat_dark.svg" />
                                        <img src="../../assets/images/message_hovered.svg" class="hovered" />
                                        消息
                                    </button>
                                    <div id="collapse_message" class="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <ul>
                                                <li>
                                                    <a href="javascript:void(0);">
                                                        <div class="icon_item">
                                                            <i class="icon">
                                                                <img src="../../assets/images/chat_lock.svg" alt="icon" />
                                                            </i>
                                                            <span class="text"><b>短信</b></span>
                                                        </div>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="javascript:void(0);" target="_blank"
                                                      data-github="document/api-cn/sms/charging-standard">

                                                      短信计费标准
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="javascript:void(0);" target="_blank"
                                                      data-github="document/api-cn/sms/mt-sending">

                                                      短信下行发送
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="javascript:void(0);" target="_blank"
                                                      data-github="document/api-cn/sms/mt-sending-v2">

                                                      短信下行发送_v2
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="javascript:void(0);" target="_blank"
                                                      data-github="document/api-cn/sms/backfill-rate">

                                                      短信回填率上报
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="javascript:void(0);" target="_blank"
                                                      data-github="document/api-cn/sms/receipt-callback">

                                                      短信回执回调
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="javascript:void(0);" target="_blank"
                                                      data-github="document/api-cn/sms/records-query">

                                                      短信记录查询
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="javascript:void(0);" target="_blank"
                                                      data-github="document/api-cn/sms/record-single-query">

                                                      短信记录单条查询
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="javascript:void(0);" target="_blank"
                                                      data-github="document/api-cn/sms/billing-report">

                                                      短信对账单报表
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="javascript:void(0);" target="_blank"
                                                      data-github="document/api-cn/sms/sms-faq">

                                                      国际短信常见问题Q&A
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="javascript:void(0);">
                                                        <div class="icon_item">
                                                            <i class="icon">
                                                                <img src="../../assets/images/bell_icon.svg" alt="icon" />
                                                            </i>
                                                            <span class="text"><b>邮件验证码</b></span>
                                                        </div>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="javascript:void(0);" target="_blank"
                                                      data-github="document/api-cn/email-code/send-code">

                                                      发送邮件验证码
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="javascript:void(0);" target="_blank"
                                                      data-github="document/api-cn/email-code/receipt-callback">

                                                      邮件验证码回执回调
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="javascript:void(0);" target="_blank"
                                                      data-github="document/api-cn/email-code/records-query">

                                                      邮件验证码记录查询
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div class="accordion-item">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse_voice" aria-expanded="false" aria-controls="collapse_voice">
                                        <img src="../../assets/images/voice_dark.svg" />
                                        <img src="../../assets/images/voice_hovered.svg" class="hovered" />
                                        语音
                                    </button>
                                    <div id="collapse_voice" class="accordion-collapse collapse hide" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <ul>
                                                <li>
                                                    <a href="javascript:void(0);">
                                                        <div class="icon_item">
                                                            <i class="icon">
                                                                <img src="../../assets/images/lock_icon.svg" alt="icon" />
                                                            </i>
                                                            <span class="text"><b>语音验证码</b></span>
                                                        </div>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="javascript:void(0);" target="_blank"
                                                      data-github="document/api-cn/voice/sending-code">

                                                      发送语音验证码
                                                    </a>
                                                </li>
                                                <li>
                                                  <a href="javascript:void(0);" target="_blank"
                                                      data-github="document/api-cn/voice/code-backfilled">

                                                      语音验证码回填上报
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="javascript:void(0);">
                                                        <div class="icon_item">
                                                            <i class="icon">
                                                                <img src="../../assets/images/speaker_icon.svg" alt="icon" />
                                                            </i>
                                                            <span class="text"><b>语音通知</b></span>
                                                        </div>
                                                    </a>
                                                </li>
                                                <li>
                                                  <a href="javascript:void(0);" target="_blank"
                                                      data-github="document/api-cn/voice/sending-group-call">

                                                      发送语音群呼
                                                    </a>
                                                </li>
                                                <li>
                                                  <a href="javascript:void(0);" target="_blank"
                                                      data-github="document/api-cn/voice/sending-notifications">

                                                      发送语音通知
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="javascript:void(0);">
                                                        <div class="icon_item">
                                                            <i class="icon">
                                                                <img src="../../assets/images/others_dark.svg" alt="icon" />
                                                            </i>
                                                            <span class="text"><b>其他</b></span>
                                                        </div>
                                                    </a>
                                                </li>
                                                <li>
                                                  <a href="javascript:void(0);" target="_blank"
                                                      data-github="document/api-cn/voice/upload-recording-file">

                                                      上传语音录音文件
                                                    </a>
                                                </li>
                                                <li>
                                                  <a href="javascript:void(0);" target="_blank"
                                                      data-github="document/api-cn/voice/upload-voice-file-v1">

                                                      上传语音文件
                                                    </a>
                                                </li>
                                                <li>
                                                  <a href="javascript:void(0);" target="_blank"
                                                      data-github="document/api-cn/voice/uploaded-file-query">

                                                      已上传录音文件查询
                                                    </a>
                                                </li>
                                                <li>
                                                  <a href="javascript:void(0);" target="_blank"
                                                      data-github="document/api-cn/voice/receipt-callback">

                                                      语音回执回调
                                                    </a>
                                                </li>
                                                <li>
                                                  <a href="javascript:void(0);" target="_blank"
                                                      data-github="document/api-cn/voice/record-query">

                                                      语音记录查询
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div class="accordion-item">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse_flash_call" aria-expanded="false" aria-controls="collapse_flash_call">
                                        <img src="../../assets/images/conversation_icon_2.svg" />
                                        <img src="../../assets/images/conversation_hovered.svg" class="hovered" />
                                        互动对话
                                    </button>
                                    <div id="collapse_flash_call" class="accordion-collapse collapse hide" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <ul>
                                                <li>
                                                    <a href="javascript:void(0);">
                                                        <div class="icon_item">
                                                            <i class="icon">
                                                                <img src="../../assets/images/super_msg_icon.svg" alt="icon" />
                                                            </i>
                                                            <span class="text"><b>Super Messaging</b></span>
                                                        </div>
                                                    </a>
                                                </li>
                                                <li>
                                                  <a href="javascript:void(0);" target="_blank"
                                                      data-github="document/api-cn/superApi/superApiMt">

                                                      发送消息
                                                    </a>
                                                </li>
                                                <li>
                                                  <a href="javascript:void(0);" target="_blank"
                                                      data-github="document/api-cn/superApi/superApiWebhook">

                                                      Webhook
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="javascript:void(0);">
                                                        <div class="icon_item">
                                                            <i class="icon">
                                                                <img src="../../assets/images/whattsap_icon.svg" alt="icon" />
                                                            </i>
                                                            <span class="text"><b>WhatsApp 消息</b></span>
                                                        </div>
                                                    </a>
                                                </li>
                                                <li>
                                                  <a href="javascript:void(0);" target="_blank"
                                                      data-github="document/api-cn/whats-app/WhatsApp-API-mt">

                                                      发送消息
                                                    </a>
                                                </li>
                                                <li>
                                                  <a href="javascript:void(0);" target="_blank"
                                                      data-github="document/api-cn/whats-app/mark-as-read">

                                                      标记入站消息已读
                                                    </a>
                                                </li>
                                                <li>
                                                  <a href="javascript:void(0);" target="_blank"
                                                      data-github="document/api-cn/whats-app/upload-media">

                                                      上传媒体文件
                                                    </a>
                                                </li>
                                                <li>
                                                  <a href="javascript:void(0);" target="_blank"
                                                      data-github="document/api-cn/whats-app/get-media">

                                                      获取媒体文件
                                                    </a>
                                                </li>
                                                <li>
                                                  <a href="javascript:void(0);" target="_blank"
                                                      data-github="document/api-cn/whats-app/delete-media">

                                                      删除媒体文件
                                                    </a>
                                                </li>
                                                <li>
                                                  <a href="javascript:void(0);" target="_blank"
                                                      data-github="document/api-cn/whats-app/webhook">

                                                      Webhook
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="javascript:void(0);">
                                                        <div class="icon_item">
                                                            <i class="icon">
                                                                <img src="../../assets/images/whattsap_icon.svg" alt="icon" />
                                                            </i>
                                                            <span class="text"><b>WhatsApp 管理</b></span>
                                                        </div>
                                                    </a>
                                                </li>
                                                <li>
                                                  <a href="javascript:void(0);" target="_blank"
                                                      data-github="document/api-cn/wa-gl/get-phone">

                                                      查询号码信息
                                                    </a>
                                                </li>
                                                <li>
                                                  <a href="javascript:void(0);" target="_blank"
                                                      data-github="document/api-cn/wa-gl/get-template">

                                                      查询消息模板
                                                    </a>
                                                </li>
                                                <li>
                                                  <a href="javascript:void(0);" target="_blank"
                                                      data-github="document/api-cn/wa-gl/create-template">

                                                      创建消息模板
                                                    </a>
                                                </li>
                                                <li>
                                                  <a href="javascript:void(0);" target="_blank"
                                                      data-github="document/api-cn/wa-gl/update-template">

                                                      编辑消息模板
                                                    </a>
                                                </li>
                                                <li>
                                                  <a href="javascript:void(0);" target="_blank"
                                                      data-github="document/api-cn/wa-gl/delete-template">

                                                      删除消息模板
                                                    </a>
                                                </li>
                                                <li>
                                                  <a href="javascript:void(0);" target="_blank"
                                                      data-github="document/api-cn/wa-gl/upload-templatefile">

                                                      上传模板示例文件
                                                    </a>
                                                </li>
                                                <li>
                                                  <a href="javascript:void(0);" target="_blank"
                                                      data-github="document/api-cn/wa-gl/WhatsApp-API-createFlow">

                                                      创建流(flow)
                                                    </a>
                                                </li>
                                                <li>
                                                  <a href="javascript:void(0);" target="_blank"
                                                      data-github="document/api-cn/wa-gl/WhatsApp-API-updateFlow">

                                                      更新流(flow)
                                                    </a>
                                                </li>
                                                <li>
                                                  <a href="javascript:void(0);" target="_blank"
                                                      data-github="document/api-cn/wa-gl/WhatsApp-API-queryFlowsById">

                                                      根据流ID查询流信息(flow)
                                                    </a>
                                                </li>
                                                <li>
                                                  <a href="javascript:void(0);" target="_blank"
                                                      data-github="document/api-cn/wa-gl/WhatsApp-API-queryFlowsByPhone">

                                                      根据whatsapp号码查询流列表(flow)
                                                    </a>
                                                </li>
                                                <li>
                                                  <a href="javascript:void(0);" target="_blank"
                                                      data-github="document/api-cn/wa-gl/WhatsApp-API-getPreviewByFlowId">

                                                      根据流ID查询流预览地址(flow)
                                                    </a>
                                                </li>
                                                <li>
                                                  <a href="javascript:void(0);" target="_blank"
                                                      data-github="document/api-cn/wa-gl/WhatsApp-API-getFlowJson">

                                                      查询流JSON(flow)
                                                    </a>
                                                </li>
                                                <li>
                                                  <a href="javascript:void(0);" target="_blank"
                                                      data-github="document/api-cn/wa-gl/WhatsApp-API-publishFlow">

                                                      发布流(flow)
                                                    </a>
                                                </li>
                                                <li>
                                                  <a href="javascript:void(0);" target="_blank"
                                                      data-github="document/api-cn/wa-gl/WhatsApp-API-deleteFlowDraft">

                                                      删除流草稿(flow)
                                                    </a>
                                                </li>
                                                <li>
                                                  <a href="javascript:void(0);" target="_blank"
                                                      data-github="document/api-cn/wa-gl/WhatsApp-API-deprecateFlow">

                                                      废弃已发布的流(flow)
                                                    </a>
                                                </li>
                                                <li>
                                                  <a href="javascript:void(0);" target="_blank"
                                                      data-github="document/api-cn/wa-gl/WhatsApp-API-updateFlowJson">

                                                      更新流JSON(flow)
                                                    </a>
                                                </li>
                                                <li>
                                                  <a href="javascript:void(0);" target="_blank"
                                                      data-github="document/api-cn/wa-gl/WhatsApp-API-uploadPublicKey">

                                                      上传业务公钥(flow)
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="javascript:void(0);">
                                                        <div class="icon_item">
                                                            <i class="icon">
                                                                <img src="../../assets/images/whattsap_icon.svg" alt="icon" />
                                                            </i>
                                                            <span class="text"><b>WhatsApp 集成商</b></span>
                                                        </div>
                                                    </a>
                                                </li>
                                                <li>
                                                  <a href="javascript:void(0);" target="_blank"
                                                      data-github="document/api-cn/wa-jcs/WhatsApp-API-createApp">

                                                      创建客户应用
                                                    </a>
                                                </li>
                                                <li>
                                                  <a href="javascript:void(0);" target="_blank"
                                                      data-github="document/api-cn/wa-jcs/WhatsApp-API-login">

                                                      集成商登录
                                                    </a>
                                                </li>
                                                <li>
                                                  <a href="javascript:void(0);" target="_blank"
                                                      data-github="document/api-cn/wa-jcs/WhatsApp-API-phoneList">

                                                      客户应用的号码列表
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="javascript:void(0);">
                                                        <div class="icon_item">
                                                            <i class="icon">
                                                                <img src="../../assets/images/whattsap2_icon.svg" alt="icon" />
                                                            </i>
                                                            <span class="text"><b>Viber Business</b></span>
                                                        </div>
                                                    </a>
                                                </li>
                                                <li>
                                                  <a href="javascript:void(0);" target="_blank"
                                                      data-github="document/api-cn/viber/Viber-API-mt">

                                                      发送消息
                                                    </a>
                                                </li>
                                                <li>
                                                  <a href="javascript:void(0);" target="_blank"
                                                      data-github="document/api-cn/viber/Viber-webhook">

                                                      Webhook
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="javascript:void(0);">
                                                        <div class="icon_item">
                                                            <i class="icon">
                                                                <img src="../../assets/images/zolo_icon.svg" alt="icon" />
                                                            </i>
                                                            <span class="text"><b>Zalo Business</b></span>
                                                        </div>
                                                    </a>
                                                </li>
                                                <li>
                                                  <a href="javascript:void(0);" target="_blank"
                                                      data-github="document/api-cn/zalo-zns/Zalo-API-mt">

                                                      发送消息
                                                    </a>
                                                </li>
                                                <li>
                                                  <a href="javascript:void(0);" target="_blank"
                                                      data-github="document/api-cn/zalo-zns/Init‐Journey‐Token">

                                                      初始化Journey Token
                                                    </a>
                                                </li>
                                                <li>
                                                  <a href="javascript:void(0);" target="_blank"
                                                      data-github="document/api-cn/zalo-zns/Check‐Journey‐Token">

                                                      校验Journey Token
                                                    </a>
                                                </li>
                                                <li>
                                                  <a href="javascript:void(0);" target="_blank"
                                                      data-github="document/api-cn/zalo-zns/Zalo-webhook">

                                                      Webhook
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div class="accordion-item">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse_short_link" aria-expanded="false" aria-controls="collapse_short_link">
                                        <img src="../../assets/images/fraud_icon_2.svg" />
                                        <img src="../../assets/images/fraud__hovered.svg" class="hovered" />
                                        防欺诈策略
                                    </button>
                                    <div id="collapse_short_link" class="accordion-collapse collapse hide" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <ul>
                                                <li>
                                                    <a href="javascript:void(0);">
                                                        <div class="icon_item">
                                                            <i class="icon">
                                                                <img src="../../assets/images/frequency_icon.svg" alt="icon" />
                                                            </i>
                                                            <span class="text"><b>号码检测</b></span>
                                                        </div>
                                                    </a>
                                                </li>
                                                <li>
                                                  <a href="javascript:void(0);" target="_blank"
                                                      data-github="document/api-cn/number-detection/Number-Check-API-submit">

                                                      提交号码检测
                                                    </a>
                                                </li>
                                                <li>
                                                  <a href="javascript:void(0);" target="_blank"
                                                      data-github="document/api-cn/number-detection/Number-Check-API-query">

                                                      号码检测查询
                                                    </a>
                                                </li>
                                                <li>
                                                  <a href="javascript:void(0);" target="_blank"
                                                      data-github="document/api-cn/number-detection/Number-Check-API-callback">

                                                      号码检测回执
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="javascript:void(0);">
                                                        <div class="icon_item">
                                                            <i class="icon">
                                                                <img src="../../assets/images/hand_icon.svg" alt="icon" />
                                                            </i>
                                                            <span class="text"><b>一键登录</b></span>
                                                        </div>
                                                    </a>
                                                </li>
                                                <li>
                                                  <a href="javascript:void(0);" target="_blank"
                                                      data-github="document/api-cn/ota/OTA-API-init">

                                                      一键登录
                                                    </a>
                                                </li>
                                                <li>
                                                  <a href="javascript:void(0);" target="_blank"
                                                      data-github="document/api-cn/ota/OTA-API-callback">

                                                      登录验证状态回执
                                                    </a>
                                                </li>
                                                <li>
                                                  <a href="javascript:void(0);" target="_blank"
                                                      data-github="document/api-cn/ota/OTA-API-verify">

                                                      身份验证结果
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div class="accordion-item">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse_email_verification" aria-expanded="false" aria-controls="collapse_email_verification">
                                        <img src="../../assets/images/global-number_icon_2.svg" />
                                        <img src="../../assets/images/number_hovered.svg" class="hovered" />
                                        全球号码
                                    </button>
                                    <div id="collapse_email_verification" class="accordion-collapse collapse hide" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <ul>
                                                <li>
                                                    <a href="javascript:void(0);">
                                                        <div class="icon_item">
                                                            <i class="icon">
                                                                <img src="../../assets/images/glasses_icon.svg" alt="icon" />
                                                            </i>
                                                            <span class="text"><b>隐私号</b></span>
                                                        </div>
                                                    </a>
                                                </li>
                                                <li>
                                                  <a href="javascript:void(0);" target="_blank"
                                                      data-github="document/api-cn/pns/PNS-API-bind">

                                                      提交AB进行绑定
                                                    </a>
                                                </li>
                                                <li>
                                                  <a href="javascript:void(0);" target="_blank"
                                                      data-github="document/api-cn/pns/PNS-API-unbind">

                                                      解绑AB绑定关系
                                                    </a>
                                                </li>
                                                <li>
                                                  <a href="javascript:void(0);" target="_blank"
                                                      data-github="document/api-cn/pns/PNS-API-query">

                                                      根据A或B查询对手号码
                                                    </a>
                                                </li>
                                                <li>
                                                  <a href="javascript:void(0);" target="_blank"
                                                      data-github="document/api-cn/pns/PNS-API-list">

                                                      查询AXB关系列表
                                                    </a>
                                                </li>
                                                <li>
                                                  <a href="javascript:void(0);" target="_blank"
                                                      data-github="document/api-cn/pns/PNS-API-webhook">

                                                      Webhook
                                                    </a>
                                                </li>
                                                <li>
                                                  <a href="javascript:void(0);" target="_blank"
                                                      data-github="document/api-cn/pns/PNS-API-Error-Code">

                                                      错误码
                                                    </a>
                                                </li>
                                                <li>
                                                  <a href="javascript:void(0);" target="_blank"
                                                      data-github="document/api-cn/pns/PNS-API-Error-Webhook">

                                                      错误信息推送
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="javascript:void(0);">
                                                        <div class="icon_item">
                                                            <i class="icon">
                                                                <img src="../../assets/images/both_side_icon.svg" alt="icon" />
                                                            </i>
                                                            <span class="text"><b>DID号码</b></span>
                                                        </div>
                                                    </a>
                                                </li>
                                                <li>
                                                  <a href="javascript:void(0);" target="_blank"
                                                      data-github="document/api-cn/did-number/sms-mt-v2">

                                                      DID号码进行短信下行v2
                                                    </a>
                                                </li>
                                                <li>
                                                  <a href="javascript:void(0);" target="_blank"
                                                      data-github="document/api-cn/did-number/mt-receipt-callback-v2">

                                                      DID短信结果回调(加签)
                                                    </a>
                                                </li>
                                                <li>
                                                  <a href="javascript:void(0);" target="_blank"
                                                      data-github="document/api-cn/did-number/mt-receipt-callback">

                                                      DID短信结果回调(无加签)
                                                    </a>
                                                </li>
                                                <li>
                                                  <a href="javascript:void(0);" target="_blank"
                                                      data-github="document/api-cn/did-number/call-records-query">

                                                      DID号码通话记录查询
                                                    </a>
                                                </li>
                                                <li>
                                                  <a href="javascript:void(0);" target="_blank"
                                                      data-github="document/api-cn/did-number/DID-API-Dialout-With-Amazon-Connect">

                                                      DID呼出并转接到Amazon坐席
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div class="accordion-item">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#customer-engagment" aria-expanded="false" aria-controls="customer-engagment">
                                        <img src="../../assets/images/customer_engagement_icon_2.svg" />
                                        <img src="../../assets/images/customer_engagement_hovered.svg" class="hovered" />
                                        客户互动
                                    </button>
                                    <div id="customer-engagment" class="accordion-collapse collapse hide" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <ul>
                                                <li>
                                                    <a href="javascript:void(0);">
                                                        <div class="icon_item">
                                                            <i class="icon">
                                                                <img src="../../assets/images/lock_icon.svg" alt="icon" />
                                                            </i>
                                                            <span class="text"><b>NXLink</b></span>
                                                        </div>
                                                    </a>
                                                </li>
                                                <li>
                                                  <a href="javascript:void(0);" target="_blank"
                                                      data-github="document/api-cn/others/SAAS-WhatsApp-API-send">

                                                      发送消息
                                                    </a>
                                                </li>
                                                <li>
                                                  <a href="javascript:void(0);" target="_blank"
                                                      data-github="document/api-cn/others/SAAS-WhatsApp-API-webhook">

                                                      Webhook
                                                    </a>
                                                </li>
                                                <li>
                                                  <a href="javascript:void(0);" target="_blank"
                                                      data-github="document/api-cn/others/SAAS-saas_plat-openapi-batch_create">

                                                      批量创建客户
                                                    </a>
                                                </li>
                                                <li>
                                                  <a href="javascript:void(0);" target="_blank"
                                                      data-github="document/api-cn/others/SAAS-saas_plat-openapi-customer_fields">

                                                      查询客户字段接口
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="javascript:void(0);">
                                                        <div class="icon_item">
                                                            <i class="icon">
                                                                <img src="../../assets/images/lock_icon.svg" alt="icon" />
                                                            </i>
                                                            <span class="text"><b>AICC(Call center)</b></span>
                                                        </div>
                                                    </a>
                                                </li>
                                                <li>
                                                  <a href="javascript:void(0);" target="_blank"
                                                      data-github="document/api-cn/nxcc/nxccIframe">

                                                      NXCC-嵌入式客户端
                                                    </a>
                                                </li>
                                                <li>
                                                  <a href="javascript:void(0);" target="_blank"
                                                      data-github="document/api-cn/nxcc/nxccSdkVue3">

                                                      NXCC-SDK组件(vue3)
                                                    </a>
                                                </li>
                                                <li>
                                                  <a href="javascript:void(0);" target="_blank"
                                                      data-github="document/api-cn/nxcc/nxccSdkVue2">

                                                      NXCC-SDK组件(vue2)
                                                    </a>
                                                </li>
                                                <li>
                                                  <a href="javascript:void(0);" target="_blank"
                                                      data-github="document/api-cn/nxcc/nxccJssdk">

                                                      NXCC-SDK组件(js sdk)
                                                    </a>
                                                </li>
                                                <li>
                                                  <a href="javascript:void(0);" target="_blank"
                                                      data-github="document/api-cn/nxcc/nxccCdr">

                                                      通话记录查询
                                                    </a>
                                                </li>
                                                <li>
                                                  <a href="javascript:void(0);" target="_blank"
                                                      data-github="document/api-cn/nxcc/nxccWebhook">

                                                      Webhook
                                                    </a>
                                                </li>
                                                <li>
                                                  <a href="javascript:void(0);" target="_blank"
                                                      data-github="document/api-cn/nxcc/nxccOrderIdCdr">

                                                      通过自定义orderId查询通话记录
                                                    </a>
                                                </li>
                                                <li>
                                                  <a href="javascript:void(0);" target="_blank"
                                                      data-github="document/api-cn/nxcc/nxccSeatInfo">

                                                      坐席信息查询
                                                    </a>
                                                </li>
                                                <li>
                                                  <a href="javascript:void(0);" target="_blank"
                                                      data-github="document/api-cn/nxcc/nxccSeatStatus">

                                                      坐席状态查询
                                                    </a>
                                                </li>
                                                <li>
                                                  <a href="javascript:void(0);" target="_blank"
                                                      data-github="document/api-cn/nxcc/nxccGroupList">

                                                      坐席组查询
                                                    </a>
                                                </li>
                                                <li>
                                                  <a href="javascript:void(0);" target="_blank"
                                                      data-github="document/api-cn/nxcc/nxccGroupChange">
                                                      坐席组更新成员
                                                    </a>
                                                </li>
                                                <li>
                                                  <a href="javascript:void(0);" target="_blank"
                                                      data-github="document/nxcc/nxccGroupAgentBind">
                                                      查询坐席组和坐席的绑定关系
                                                    </a>
                                                </li>
                                                <li>
                                                  <a href="javascript:void(0);" target="_blank"
                                                      data-github="document/nxcc/nxccAgentNumber">
                                                      查询坐席外呼显号
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="javascript:void(0);">
                                                        <div class="icon_item">
                                                            <i class="icon">
                                                                <img src="../../assets/images/lock_icon.svg" alt="icon" />
                                                            </i>
                                                            <span class="text"><b>AICC(Call bot)</b></span>
                                                        </div>
                                                    </a>
                                                </li>
                                                <li>
                                                  <a href="javascript:void(0);" target="_blank"
                                                      data-github="document/api-cn/callbot/operation">

                                                      操作手册
                                                    </a>
                                                </li>
                                                <li>
                                                  <a href="javascript:void(0);" target="_blank"
                                                      data-github="document/api-cn/callbot/botApiOverview">

                                                      Callbot API概述
                                                    </a>
                                                </li>
                                                <li>
                                                  <a href="javascript:void(0);" target="_blank"
                                                      data-github="document/api-cn/callbot/botApiAuth">

                                                      Callbot API鉴权
                                                    </a>
                                                </li>
                                                <li>
                                                  <a href="javascript:void(0);" target="_blank"
                                                      data-github="document/api-cn/callbot/botCreatAutoTask">

                                                      创建自动拨号任务
                                                    </a>
                                                </li>
                                                <li>
                                                  <a href="javascript:void(0);" target="_blank"
                                                      data-github="document/api-cn/callbot/botBatchAddCallList">

                                                      批量添加拨打名单
                                                    </a>
                                                </li>
                                                <li>
                                                  <a href="javascript:void(0);" target="_blank"
                                                      data-github="document/api-cn/callbot/botCreatAddTaskList">

                                                      创建自动拨号任务并添加拨打名单
                                                    </a>
                                                </li>
                                                <li>
                                                  <a href="javascript:void(0);" target="_blank"
                                                      data-github="document/api-cn/callbot/botTaskOpenClose">

                                                      任务控制(启动/暂停)
                                                    </a>
                                                </li>
                                                <li>
                                                  <a href="javascript:void(0);" target="_blank"
                                                      data-github="document/api-cn/callbot/botUpdateTask">

                                                      更新任务
                                                    </a>
                                                </li>
                                                <li>
                                                  <a href="javascript:void(0);" target="_blank"
                                                      data-github="document/api-cn/callbot/botGetTaskList">

                                                      获取任务列表
                                                    </a>
                                                </li>
                                                <li>
                                                  <a href="javascript:void(0);" target="_blank"
                                                      data-github="document/api-cn/callbot/botGetCallOrderList">

                                                      获取拨打订单列表
                                                    </a>
                                                </li>
                                                <li>
                                                  <a href="javascript:void(0);" target="_blank"
                                                      data-github="document/api-cn/callbot/botStopOrderCall">

                                                      停止订单拨打
                                                    </a>
                                                </li>
                                                <li>
                                                  <a href="javascript:void(0);" target="_blank"
                                                      data-github="document/api-cn/callbot/botQueryOrderCall">

                                                      查询订单维度拨打详情
                                                    </a>
                                                </li>
                                                <li>
                                                  <a href="javascript:void(0);" target="_blank"
                                                      data-github="document/api-cn/callbot/botQueryCallBack">

                                                      通话维度回调
                                                    </a>
                                                </li>
                                                <li>
                                                  <a href="javascript:void(0);" target="_blank"
                                                      data-github="document/api-cn/callbot/botQueryOrderBack">

                                                      订单维度拨打回调
                                                    </a>
                                                </li>
                                                <li>
                                                  <a href="javascript:void(0);" target="_blank"
                                                      data-github="document/api-cn/callbot/botTaskStatusBack">

                                                      任务状态回调
                                                    </a>
                                                </li>
                                                <li>
                                                  <a href="javascript:void(0);" target="_blank"
                                                      data-github="document/api-cn/callbot/botCallList">
                                                      通话列表
                                                    </a>
                                                </li>
                                                <li>
                                                  <a href="javascript:void(0);" target="_blank"
                                                      data-github="document/api-cn/callbot/botExportCall">
                                                      批次-导出最新批次数据
                                                    </a>
                                                </li>
                                                <li>
                                                  <a href="javascript:void(0);" target="_blank"
                                                      data-github="document/api-cn/callbot/botImportCall">
                                                      批次-导入最新批次数据
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div class="accordion-item">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse_others" aria-expanded="false" aria-controls="collapse_others">
                                        <img src="../../assets/images/others_dark.svg" />
                                        <img src="../../assets/images/others_dark_hovered.svg" class="hovered" />
                                        其他
                                    </button>
                                    <div id="collapse_others" class="accordion-collapse collapse hide" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <ul>
                                                <li>
                                                    <a href="javascript:void(0);">
                                                        <div class="icon_item">
                                                            <i class="icon">
                                                                <img src="../../assets/images/exclamation_dark.svg" alt="icon" />
                                                            </i>
                                                            <span class="text"><b>坐席</b></span>
                                                        </div>
                                                    </a>
                                                </li>
                                                <li>
                                                  <a href="javascript:void(0);" target="_blank" 
                                                      data-github="document/api-cn/nxphone/pc-usage">

                                                      NXphone PC 使用说明
                                                    </a>
                                                </li>
                                                <li>
                                                  <a href="javascript:void(0);" target="_blank" 
                                                      data-github="document/api-cn/nxphone/android-usage">

                                                      NXphone Android 使用说明
                                                    </a>
                                                </li>
                                                <li>
                                                  <a href="javascript:void(0);" target="_blank" 
                                                      data-github="document/api-cn/nxphone/android-sdk">

                                                      NXphone Android SDK 接入文档
                                                    </a>
                                                </li>
                                                <li>
                                                  <a href="javascript:void(0);" target="_blank" 
                                                      data-github="document/api-cn/nxphone/hang-up-explained">

                                                      呼叫挂断原因解释
                                                    </a>
                                                </li>
                                                <li>
                                                  <a href="javascript:void(0);" target="_blank" 
                                                      data-github="document/api-cn/nxphone/cdr-query-interface">

                                                      话单CDR查询接口
                                                    </a>
                                                </li>
                                                <li>
                                                  <a href="javascript:void(0);" target="_blank" 
                                                      data-github="document/api-cn/nxphone/cdr-callback-v1">

                                                      话单CDR回调接口说明(V1.0)
                                                    </a>
                                                </li>
                                                <li>
                                                  <a href="javascript:void(0);" target="_blank" 
                                                      data-github="document/api-cn/nxphone/api-call-v1">

                                                      坐席API调用接口说明(V1.0)
                                                    </a>
                                                </li>
                                                <li>
                                                  <a href="javascript:void(0);" target="_blank" 
                                                      data-github="document/api-cn/nxphone/orderid-query-cdr">

                                                      根据orderid查询话单CDR接口
                                                    </a>
                                                </li>
                                                <li>
                                                  <a href="javascript:void(0);" target="_blank" 
                                                      data-github="document/api-cn/nxphone/sip-call">

                                                      坐席系统sip链接调用方式(推荐)
                                                    </a>
                                                </li>
                                                <li>
                                                  <a href="javascript:void(0);" target="_blank" 
                                                      data-github="document/api-cn/nxphone/number-desensitization">

                                                      号码脱敏处理
                                                    </a>
                                                </li>
                                                <li>
                                                  <a href="javascript:void(0);" target="_blank" 
                                                      data-github="document/api-cn/nxphone/changing-interface">

                                                      修改话机密码接口
                                                    </a>
                                                </li>
                                                <li>
                                                  <a href="javascript:void(0);" target="_blank" 
                                                      data-github="document/api-cn/nxphone/query-approval">

                                                      查询审批单
                                                    </a>
                                                </li>
                                                <li>
                                                  <a href="javascript:void(0);" target="_blank" 
                                                      data-github="document/api-cn/nxphone/query-phone">

                                                      查询话机
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="javascript:void(0);">
                                                        <div class="icon_item">
                                                            <i class="icon">
                                                                <img src="../../assets/images/exclamation_dark.svg" alt="icon" />
                                                            </i>
                                                            <span class="text"><b>短链</b></span>
                                                        </div>
                                                    </a>
                                                </li>
                                                <li>
                                                  <a href="javascript:void(0);" target="_blank"
                                                      data-github="document/api-cn/short-chain/generate">

                                                      生成短链
                                                    </a>
                                                </li>
                                                <li>
                                                  <a href="javascript:void(0);" target="_blank"
                                                      data-github="document/api-cn/short-chain/query">

                                                      查询短链
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="javascript:void(0);">
                                                        <div class="icon_item">
                                                            <i class="icon">
                                                                <img src="../../assets/images/exclamation_dark.svg" alt="icon" />
                                                            </i>
                                                            <span class="text"><b>通用</b></span>
                                                        </div>
                                                    </a>
                                                </li>
                                                <li>
                                                  <a href="javascript:void(0);" target="_blank" 
                                                      data-github="document/api-cn/general/balance-query">

                                                      客户查询
                                                    </a>
                                                </li>
                                                <!-- <li>
                                                    <a href="javascript:void(0);">
                                                        <div class="icon_item">
                                                            <i class="icon">
                                                                <img src="../../assets/images/agency_dark.svg" alt="icon" />
                                                            </i>
                                                            <span class="text"><b>功能模块</b></span>
                                                        </div>
                                                    </a>
                                                </li>
                                                <li>
                                                  <a href="javascript:void(0);" target="_blank"
                                                      data-github="document/api-cn/functional/overview">

                                                      NXcloud SDK
                                                    </a>
                                                </li>
                                                <li>
                                                  <a href="javascript:void(0);" target="_blank"
                                                      data-github="document/api-cn/functional/mail">

                                                      邮件模块
                                                    </a>
                                                </li>
                                                <li>
                                                  <a href="javascript:void(0);" target="_blank"
                                                      data-github="document/api-cn/functional/sms">

                                                      短信模块
                                                    </a>
                                                </li>
                                                <li>
                                                  <a href="javascript:void(0);" target="_blank"
                                                      data-github="document/api-cn/functional/voice">

                                                      语音模块
                                                    </a>
                                                </li> -->
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="resultNotFound" class="resultNotFound">
                                <p class="alert alert-danger" role="alert">抱歉，没有找到任何结果</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-9">
                    <!-- <div class="center_area hide_temp">
                <img src="../../assets/images/centre_image.png" class="w-100" />
              </div> -->
                    <div class="center_area">
                        <div class="git_section_area">
                            <!-- <div class="markdown-body" v-html="renderedMarkdown"></div> -->
                            <v-md-preview class="markdown-body" :text="markdownContent"></v-md-preview>
                        </div>
                        <div class="pageLoad_loader" style="display: none"></div>
                    </div>
                </div>
                <div class="col-lg-3 hide_temp">
                    <div class="sidebar_area_right">
                        <div class="table_content">
                            <h2>Table of contents</h2>
                            <ul>
                                <li>Brief Description</li>
                                <li>Request Method</li>
                                <li>Request Example</li>
                                <li>Response Example</li>
                                <li>Response Parameter Description</li>
                                <li>Note</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="cts">
        <img src="../../assets/images/footer-shape.png" alt="" class="ftr_shape" />
        <div class="container">
            <div class="row row-cts">
                <div class="col-md-8">
                    <h3 class="archived-hdng cts-hdng">
                        开始为您的客户提供 <br />
                        更优质的服务体验
                    </h3>
                </div>

                <div class="col-md-4">
                    <div class="cts-btn">
                        <router-link to="/zh-sign-up" class="btn btn-success">
                            开始使用
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </section>
</main>
</template>

<script>

export default {
    name: 'ZhDeveloperPage',

    data() {
        return {
            htmlNameList: [
              "document/api-cn/nxphone/hang-up-explained",
            ],
            markdownContent: "",
            renderedMarkdown: "",
            currBtnData: "",
            docSearchVal: "",
            resultNotFound: false
        };
    },
    watch: {
      '$route.path': {
        handler: 'getMdFile',
        immediate: true,
      },
      '$route': {
        handler: 'scrollToView',
        immediate: true,
      },
    },
    methods: {
        async getMdFile() {
          window.scrollTo(0, 0);
          // this.mdFile = null;
          const category = this.$route.params.catogory;
          const doc = this.$route.params.doc || "index";
          console.log("category", category)
          console.log("doc", doc)
          const mdName = `document/api-cn/${category}/${doc}`;
          this.getDataApi(mdName);
          this.$nextTick(() => {
            this.setDomSelect(mdName)
          })
        },
        async scrollToView() {
          const hash = this.$route.hash; // 获取URL中的#id部分  
          if (hash) {
            const elementId = hash.slice(1); // 去掉#，得到元素的ID  
            const element = document.querySelector(`[data-v-md-heading="${elementId}"]`);
            if (element) {
              element.scrollIntoView({ behavior: 'smooth' }); // 平滑滚动到元素  
            }
          }
        },
        async setDomSelect(mdName) {
          let elements = document.querySelectorAll(`[data-github="${mdName}"]`);
          if(elements.length > 0) {
            let parentElement = elements[0].closest('.accordion-item');
            let btn = parentElement.querySelector('.accordion-button.collapsed');
            if(btn) {
              btn.click();
            }
            elements[0].click();
          }
        },
        async getDataApi(url) {
            try {
                console.log("url: ", url);
                // Assuming url includes the correct path
                const markdownFile = await import(`!!raw-loader!/${url}.md`);
                if(this.htmlNameList.includes(url)) {
                  this.markdownContent = markdownFile.default;
                }else {
                  this.markdownContent = markdownFile.default;
                }
            } catch (error) {
                console.error("Error loading Markdown file:", error);
            }
        },

        handleClick(event) {
            event.preventDefault();
            const currBtn = event.currentTarget;
            const currGitData = currBtn.getAttribute("data-github");
            if(currGitData) {
              this.currBtnData = currGitData;
              this.clearBgcolor();
              currBtn.setAttribute("style", "background-color: #e1ff9c;");
              console.log("currBtnData: ", this.currBtnData);
              this.$router.push({
                path: `/${currGitData.replace("/api-cn", "")}`,
              });
            }
        },
        clearBgcolor() {
            const developerPageDiv = document.querySelector('.developer_page'); // 获取<div class="developer_page">元素
            if (developerPageDiv) {
                const anchorTags = developerPageDiv.getElementsByTagName('a'); // 获取<div class="developer_page">下的所有<a>标签
                for (let i = 0; i < anchorTags.length; i++) {
                let anchorTag = anchorTags[i];
                if (anchorTags[i].getAttribute("data-github")) {
                    let computedStyle = getComputedStyle(anchorTag);
                    anchorTag.style.backgroundColor = computedStyle.backgroundColor; // 将<a>标签的computed background-color应用到普通状态下的background-color
                    anchorTag.style.removeProperty('background-color'); // 移除<a>标签的普通状态下的background-color
                }
                }
            }
        },
        docSearchFun() {
            const sidebar_options_wrap = document.querySelector(".sidebar_options_wrap");
            const sidebar_accordion_items = sidebar_options_wrap.querySelectorAll(".accordion-item");
            const sidebar_accordion_ul = sidebar_options_wrap.querySelectorAll("ul");
            const sidebar_all_options = sidebar_options_wrap.querySelectorAll("li");
            let docSearchVal = this.docSearchVal;
            docSearchVal = docSearchVal.toLowerCase();

            let optionTextNode, optionText, anchor;
            sidebar_all_options.forEach(option => {
                if (option.querySelector(".text b")) {
                    optionText = option.querySelector(".text b").textContent.toLowerCase();
                    optionTextNode = optionText;
                } else {
                    anchor = option.querySelector("a").textContent.toLowerCase();
                    optionTextNode = anchor;
                }
                // check list item's inner text with input's value
                if ((optionTextNode.indexOf(docSearchVal) != -1)) {
                    option.classList.add("show");
                    option.classList.remove("hide");
                } else {
                    option.classList.add("hide");
                    option.classList.remove("show");
                }

            });

            // hide sidebar_accordion_item if all options are hide 
            sidebar_accordion_ul.forEach(ul => {
                const liAreFound = ul.querySelectorAll("li.show");
                if (!liAreFound.length) {
                    ul.closest(".accordion-item").classList.add("hide");
                    ul.closest(".accordion-item").classList.remove("show");
                } else {
                    ul.closest(".accordion-item").classList.add("show");
                    ul.closest(".accordion-item").classList.remove("hide");
                }
            });

            // show error if search not found
            let allItemsHide = true;
            sidebar_accordion_items.forEach(item => {
                if (item.classList.contains("show")) {
                    allItemsHide = false;
                    return;
                }
            });
            this.resultNotFound = allItemsHide;
        },
    },

    mounted() {
        const sidebarOptions = document.querySelectorAll(".developer_page .sidebar_options_wrap a");
        sidebarOptions.forEach(option => {
            option.addEventListener('click', this.handleClick);
        });
        // let query = this.$route.query;
        // if(query.open) {
        //   this.getDataApi(`document/api-cn/${query.open}`);
        // }else {
        //   sidebarOptions[1].click();
        // }
    }
};
</script>
